html,
body {
  font-family: Roboto;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #f6f6f6 !important;
  &::-webkit-scrollbar {
    display: none;
  }
  .MuiLinearProgress-root {
    top: 0;
    position: sticky;
    &.load {
      opacity: 1;
    }
    &.un__load {
      opacity: 0;
    }
  }
}
